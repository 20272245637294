import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/App.css";
import { RouterProvider } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { AppProvider } from "./context/ContextProvider";
import reactroutes from "reactRouter";
import "./index.css"
const App: React.FC = () => {
  return <RouterProvider router={reactroutes} />;
};

const root = createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider toastOptions={{ defaultOptions: { duration: 1000 } }} theme={theme}>
    <AppProvider>
      
        <App />
      
    </AppProvider>
  </ChakraProvider>
);


